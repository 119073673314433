import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/app/hooks";
import TPFilters from "@/components/TPFilters/TPFilters";
import {
  setSelectedCategories,
  setSelectedCompany,
  clearAllFilters,
} from "@/modules/trainingPortal/features/Courses/slices/courseUISlice";
import { selectCurrentRequestState } from "../../selectors/courseManagementSelectors";
import { FILTER_SECTIONS } from "../../constants/courseConstants";
import {
  CourseManagementFiltersProps,
  FilterType,
  StatusValue,
  FilterSection,
} from "../../types/types";
import {
  selectCategoryOptions,
  selectSelectedFilters,
} from "../../selectors/courseManagementSelectors";

const CourseManagementFilters: React.FC<CourseManagementFiltersProps> = ({
  onFilterChange,
}) => {
  const dispatch = useAppDispatch();
  const categoryOptions = useSelector(selectCategoryOptions);
  const currentRequest = useSelector(selectCurrentRequestState);
  const selectedFilters = useSelector(selectSelectedFilters);

  const isFilterType = (type: string): type is FilterType => {
    return ["category", "status"].includes(type);
  };

  const isStatusValue = (value: string): value is StatusValue => {
    return ["ublished", "Draft"].includes(value);
  };

  const handleToggleFilter = useCallback(
    (type: string, value: string) => {
      if (!isFilterType(type)) return;
      if (type === "category") {
        const categoryId = Number(value);
        dispatch(setSelectedCategories([value]));
        onFilterChange?.({
          ...currentRequest,
          courseCategoryId: categoryId,
          isPublished: currentRequest.isPublished ?? null,
        });
      }

      if (type === "status") {
        const statusValue = isStatusValue(value) ? value : "Draft";
        dispatch(
          setSelectedCompany(statusValue === "Published" ? statusValue : null),
        );
        onFilterChange?.({
          ...currentRequest,
          courseCategoryId: currentRequest.courseCategoryId ?? null,
          isPublished: statusValue === "Published",
        });
      }
    },
    [dispatch, currentRequest, onFilterChange],
  );

  const handleClearFilters = useCallback(() => {
    dispatch(clearAllFilters());
    onFilterChange?.({
      ...currentRequest,
      courseCategoryId: null,
      isPublished: null,
    });
  }, [dispatch, currentRequest, onFilterChange]);

  const sections: FilterSection[] = useMemo(
    () => [
      {
        ...FILTER_SECTIONS.CATEGORIES,
        type: "category" as FilterType,
        options: categoryOptions,
      },
      {
        ...FILTER_SECTIONS.STATUS,
        type: "status" as FilterType,
      },
    ],
    [categoryOptions],
  );

  return (
    <TPFilters
      buttonClassName="h-10 min-w-[120px] px-4 py-2 hover:bg-gray-200"
      onClearFilters={handleClearFilters}
      onToggleFilter={handleToggleFilter}
      sections={sections}
      selectedFilters={selectedFilters}
    />
  );
};

export default CourseManagementFilters;
