import * as z from "zod";

// Base schema for course fields
const courseBaseSchema = {
  courseName: z
    .string()
    .min(1, "Course name is required")
    .max(100, "Course name cannot exceed 100 characters"),
  courseCode: z
    .string()
    .min(1, "Course code is required")
    .max(20, "Course code cannot exceed 20 characters")
    .regex(
      /^[A-Za-z0-9-]+$/,
      "Course code can only contain letters, numbers, and hyphens",
    ),
  courseDescription: z
    .string()
    .min(1, "Description is required")
    .max(1000, "Description cannot exceed 1000 characters"),
  coursePrice: z.coerce
    .number({ invalid_type_error: "Price must be a valid number" })
    .min(0, "Price must be greater than or equal to 0")
    .max(99999.99, "Price cannot exceed 99,999.99")
    .transform((val) => Number(val.toFixed(2))), // Ensure 2 decimal places
} as const;

// Schema for creating a new course
export const courseSchema = z.object(courseBaseSchema);

// Schema for editing an existing course
export const courseEditSchema = z
  .object({
    ...courseBaseSchema,
  })
  .strict(); // Ensures no extra fields

export const inputTypeSchema = z.enum(["text", "number", "textarea"]);

// Type for form data
export type CourseFormData = z.infer<typeof courseSchema>;

// Type for validated course data
export type ValidatedCourseData = Readonly<CourseFormData>;

// Type for form field names
export type CourseFormFields = keyof CourseFormData;

// Type for form errors
export type CourseFormErrors = Partial<Record<CourseFormFields, string>>;
