import React from "react";
import { Route } from "react-router-dom";
import MainLayout from "../../components/MainLayout/MainLayout";
import { AuthenticationGuard } from "../../components/AuthenticationGuard/AuthenticationGuard";
import CourseManagement from "@/modules/trainingPortal/features/CourseManagement/components/CourseManagement/CourseManagement";

const TrainingPortalRoutes = (
  <Route
    element={
      <AuthenticationGuard
        component={() => <MainLayout section="training-portal" />}
      />
    }
    path="/training-portal"
  >
    <Route element={<CourseManagement />} path="admin/course-management" />
  </Route>
);

export default TrainingPortalRoutes;
