import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useDebouncedValue } from "../../../../../../hooks/useDebouncedValue";
import { setCurrentRequest } from "../../../Courses/slices/courseUISlice";
import { selectCurrentRequestState } from "../../selectors/courseManagementSelectors";
import TPSearch from "../../../../../../components/TPSearch/TPSearch";
import {
  CourseManagementSearchProps,
  SearchChangeHandler,
} from "../../types/types";

const CourseManagementSearch: FC<CourseManagementSearchProps> = ({
  onSearch,
}) => {
  const dispatch = useAppDispatch();
  const currentRequest = useAppSelector(selectCurrentRequestState);
  const debouncedSearchQuery = useDebouncedValue(
    currentRequest.searchTerm ?? "",
    50,
  );

  const handleSearchChange: SearchChangeHandler = useCallback(
    (query: string) => {
      dispatch(
        setCurrentRequest({
          page: 1,
          searchTerm: query,
        }),
      );
      onSearch(query);
    },
    [dispatch, onSearch],
  );

  return (
    <TPSearch
      onSearchChange={handleSearchChange}
      placeholder="Search courses..."
      searchQuery={debouncedSearchQuery}
    />
  );
};

export default CourseManagementSearch;
