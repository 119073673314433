import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/app/rootReducer";
import { ApiCourse } from "@/api/fetchCourses/types";
import { AppDispatch } from "@/app/store";
import {
  toggleCourseSelection,
  setSelectedCourses,
  selectAllCourses,
} from "@/modules/trainingPortal/features/Courses/slices/coursesSlice";
import {
  duplicateCourseAction,
  deleteCourseAction,
  fetchCoursesAction,
} from "@/modules/trainingPortal/features/Courses/thunks/courseThunks";
import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator";
import { CourseManagementTableHeader } from "../CourseManagementTableHeader/CourseManagementTableHeader";
import { CourseManagementPagination } from "../CourseManagementPagination/CourseManagementPagination";
import { useTableColumns } from "../../hooks/useTableColumns";
import { useToastSuccess } from "../../hooks/useToastSuccess";
import { useToastError } from "../../hooks/useToastError";
import { Column, CourseManagementTableProps } from "../../types/types";

const CourseManagementTable: React.FC<CourseManagementTableProps> = ({
  onEditCourse,
  onPaginationChange,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const data = useSelector(selectAllCourses);
  const isLoading = useSelector((state: RootState) => state.courses.loading);
  const totalCount = useSelector(
    (state: RootState) => state.courses.totalCount,
  );
  const currentRequest = useSelector(
    (state: RootState) => state.courseUI.currentRequest,
  );

  const pagination = {
    pageIndex: (currentRequest?.page ?? 1) - 1,
    pageSize: currentRequest?.pageSize ?? 10,
    totalCount: totalCount,
  };
  const selectedCourses = useSelector(
    (state: RootState) => state.courses.selectedIds,
  );
  const { executeToastSuccess } = useToastSuccess();
  const { executeToastError } = useToastError();

  const handleSelectAllCourses = useCallback(
    (checked: boolean) => {
      if (checked) {
        dispatch(setSelectedCourses(data.map((course) => course.id)));
      } else {
        dispatch(setSelectedCourses([]));
      }
    },
    [data, dispatch],
  );

  const handleSelectCourse = useCallback(
    (courseId: number) => {
      dispatch(toggleCourseSelection(courseId));
    },
    [dispatch],
  );

  const handleCreateCourseSuccess = useCallback(async () => {
    // Calculate the last page
    const lastPage = Math.ceil((totalCount + 1) / pagination.pageSize);
    // Update pagination to the last page
    if (onPaginationChange) {
      await onPaginationChange(lastPage - 1, pagination.pageSize);
    }

    // Then fetch the courses
    return dispatch(fetchCoursesAction()).unwrap();
  }, [dispatch, totalCount, pagination.pageSize, onPaginationChange]);

  const handleDuplicateCourse = useCallback(
    async (course: ApiCourse) => {
      await executeToastSuccess(
        async () => {
          await dispatch(
            duplicateCourseAction({
              courseId: course.id,
              courseCode: `${course.courseCode}-copy`,
              courseName: `${course.courseName} (Copy)`,
            }),
          ).unwrap();
          return handleCreateCourseSuccess();
        },
        {
          title: "Course duplicated",
          description: "The course has been successfully duplicated.",
        },
      );
    },
    [dispatch, executeToastSuccess, handleCreateCourseSuccess],
  );

  const handleDeleteCourse = useCallback(
    async (courseId: number) => {
      await executeToastError(async () => {
        await dispatch(deleteCourseAction(courseId)).unwrap();
        return dispatch(fetchCoursesAction()).unwrap();
      }, "Failed to delete the course. Please try again.");
    },
    [dispatch, executeToastError],
  );

  const columns: Column[] = useTableColumns({
    selectedCourses,
    onSelectCourse: handleSelectCourse,
    onEditCourse,
    onDuplicateCourse: handleDuplicateCourse,
    onDeleteCourse: handleDeleteCourse,
    onSelectAll: handleSelectAllCourses,
    isAllSelected:
      data.length > 0 &&
      data.every((course) => selectedCourses.includes(course.id)),
  });

  return (
    <div className="relative space-y-4">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
          <LoadingIndicator />
        </div>
      )}
      <div className="rounded-md border shadow-sm">
        <div className="relative w-full overflow-auto">
          <table className="w-full caption-bottom text-sm">
            <CourseManagementTableHeader columns={columns} />
            <tbody className="[&_tr:last-child]:border-0">
              {data.length === 0 ? (
                <tr className="border-b transition-colors hover:bg-muted/50">
                  <td
                    className="p-10 text-center text-muted-foreground"
                    colSpan={columns.length + 1}
                  >
                    No courses found
                  </td>
                </tr>
              ) : (
                <>
                  {data.map((course) => (
                    <tr
                      className="border-b transition-colors hover:bg-muted/50"
                      key={course.id}
                    >
                      {columns.map((column) => (
                        <td
                          className="p-4 align-middle [&:has([role=checkbox])]:pr-0"
                          key={column.id || column.accessorKey}
                        >
                          {column.cell
                            ? column.cell({ row: { original: course } })
                            : course[column.accessorKey as keyof ApiCourse]}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {pagination.pageIndex <
                    Math.ceil(pagination.totalCount / pagination.pageSize) -
                      1 &&
                    Array.from({
                      length: pagination.pageSize - data.length,
                    }).map((_, index) => (
                      <tr className="border-b" key={`empty-${index}`}>
                        {columns.map((column, colIndex) => (
                          <td
                            className="p-4 align-middle"
                            key={`empty-${index}-${colIndex}`}
                          >
                            <div className="h-6" />
                          </td>
                        ))}
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <CourseManagementPagination
        onPageChange={onPaginationChange}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        totalCount={pagination.totalCount}
      />
    </div>
  );
};

export default CourseManagementTable;
