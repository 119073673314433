import axios from "../axiosInstance";
import { CreateCourseRequest, CreateCourseResponse } from "./types";

export const createCourse = async (
  requestData: CreateCourseRequest,
): Promise<CreateCourseResponse> => {
  const response = await axios.post<CreateCourseResponse>(
    `/api/private/courses/create`,
    requestData,
  );
  return response.data;
};
