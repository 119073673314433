import React, { useState } from "react";
import { useTotalSalesAgeDistributionChartData } from "../../hooks/useTotalSalesAgeDistributionChartData";
import { Card, CardContent } from "@/components/Card/Card";
import { ResponsiveContainer } from "recharts";
import TotalSalesAgeDistributionChart from "@/modules/stochastic/features/Chart/TotalSalesAgeDistributionChart/TotalSalesAgeDistributionChart";
import TotalSalesByYearAndMonthChart from "@/modules/stochastic/features/Chart/TotalSalesByYearAndMonthChart/TotalSalesByYearAndMonthChart";
import { useTotalSalesByYearAndMonthChartData } from "@/modules/stochastic/features/DashboardPage/hooks/useTotalSalesByYearAndMonthChartData";
import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator";
import { useTotalSalesNewVsExistingCustomerChartData } from "@/modules/stochastic/features/DashboardPage/hooks/useTotalSalesNewVsExistingCustomerChartData";
import TotalSalesNewVsExistingCustomerChart from "@/modules/stochastic/features/Chart/TotalSalesNewVsExistingCustomerChart/TotalSalesNewVsExistingCustomerChart";
import { FilterOptions } from "@/api/fetchTotalSalesAgeDistributionChartData/types";

export default function StochasticDashboard() {
  const defaultFilters: FilterOptions = {
    years: [],
    cities: [],
    trades: [],
  };

  const [
    totalSalesAgeDistributionChartFilters,
    setTotalSalesAgeDistributionChartFilters,
  ] = useState<FilterOptions>(defaultFilters);

  const {
    chartData: totalSalesAgeDistributionChartData,
    loading: isTotalSalesAgeDistributionChartDataLoading,
  } = useTotalSalesAgeDistributionChartData(
    totalSalesAgeDistributionChartFilters,
  );
  const {
    chartData: totalSalesByYearAndMonthChartData,
    loading: isTotalSalesByYearAndMonthChartLoading,
  } = useTotalSalesByYearAndMonthChartData();
  const {
    chartData: totalSalesNewVsExistingCustomerChartData,
    loading: isTotalSalesNewVsExistingCustomerChartLoading,
  } = useTotalSalesNewVsExistingCustomerChartData();

  const handleTotalSalesAgeDistributionChartFilters = (
    filterType: "cities" | "years" | "trades",
    values: string[],
  ) => {
    setTotalSalesAgeDistributionChartFilters((prev) => ({
      ...prev,
      [filterType]: values,
    }));
  };

  return (
    <div className="bg-white dark:bg-secondary-dark min-h-screen p-8 transition-colors duration-200">
      <div className="max-w-7xl mx-auto space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="dark:text-light font-bold text-3xl text-fontColor">
            Dashboard
          </h1>
        </div>

        <ChartWrapper loading={isTotalSalesNewVsExistingCustomerChartLoading}>
          <TotalSalesNewVsExistingCustomerChart
            initialData={totalSalesNewVsExistingCustomerChartData?.data || []}
          />
        </ChartWrapper>

        <ChartWrapper loading={isTotalSalesByYearAndMonthChartLoading}>
          <TotalSalesByYearAndMonthChart
            initialData={totalSalesByYearAndMonthChartData?.data || []}
          />
        </ChartWrapper>

        <ChartWrapper loading={isTotalSalesAgeDistributionChartDataLoading}>
          <TotalSalesAgeDistributionChart
            filterOptions={
              totalSalesAgeDistributionChartData?.data.filterOptions ||
              defaultFilters
            }
            filters={totalSalesAgeDistributionChartFilters}
            handleFilterChange={handleTotalSalesAgeDistributionChartFilters}
            initialData={
              totalSalesAgeDistributionChartData?.data.chartData || []
            }
          />
        </ChartWrapper>
      </div>
    </div>
  );
}

interface ChartWrapperProps {
  loading: boolean;
  children: React.ReactNode;
}

const ChartWrapper: React.FC<ChartWrapperProps> = ({ loading, children }) => (
  <div className="w-full">{loading ? <ChartLoader /> : children}</div>
);

const ChartLoader: React.FC = () => (
  <Card className="w-full bg-white dark:bg-secondary-dark">
    <CardContent>
      <ResponsiveContainer height={500} width="100%">
        <div className="flex items-center justify-center h-full">
          <div className="bg-white bg-opacity-75">
            <LoadingIndicator />
          </div>
        </div>
      </ResponsiveContainer>
    </CardContent>
  </Card>
);
