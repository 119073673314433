import axios from "../axiosInstance";
import { DeleteCourseResponse } from "./types";

export const deleteCourse = async (
  courseId: number,
): Promise<DeleteCourseResponse> => {
  const response = await axios.delete<DeleteCourseResponse>(
    `/api/private/courses/${courseId}`,
  );
  return response.data;
};
