import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchCoursesRequest } from "@/api/fetchCourses/types";
import { RootState } from "@/app/rootReducer";
import { ApiCourse } from "@/api/fetchCourses/types";

// Define the state shape for UI-related state
interface CourseUIState {
  currentRequest: FetchCoursesRequest;
  selectedCategories: string[];
  selectedDurations: string[];
  selectedCompany: string | null;
  selectedSession: string | null;
  priceRange: {
    min: number;
    max: number;
  };
  showCourseDialog: boolean;
  courseToEdit: ApiCourse | null;
}

const initialState: CourseUIState = {
  currentRequest: {
    page: 1,
    pageSize: 10,
    searchTerm: "",
    sortBy: undefined,
    sortOrder: undefined,
  },
  selectedCategories: [],
  selectedDurations: [],
  selectedCompany: null,
  selectedSession: null,
  priceRange: {
    min: 0,
    max: 1000,
  },
  showCourseDialog: false,
  courseToEdit: null,
};

const courseUISlice = createSlice({
  name: "courseUI",
  initialState,
  reducers: {
    setCurrentRequest: (
      state,
      action: PayloadAction<Partial<FetchCoursesRequest>>,
    ) => {
      state.currentRequest = {
        ...state.currentRequest,
        ...action.payload,
      };
    },
    resetPagination: (state) => {
      state.currentRequest.page = 1;
      state.currentRequest.pageSize = 10;
    },
    setSelectedCategories: (state, action: PayloadAction<string[]>) => {
      state.selectedCategories = action.payload;
      state.currentRequest.page = 1;
    },
    setSelectedDurations: (state, action: PayloadAction<string[]>) => {
      state.selectedDurations = action.payload;
      state.currentRequest.page = 1;
    },
    setSelectedCompany: (state, action: PayloadAction<string | null>) => {
      state.selectedCompany = action.payload;
      state.currentRequest.page = 1;
    },
    setSelectedSession: (state, action: PayloadAction<string | null>) => {
      state.selectedSession = action.payload;
      state.currentRequest.page = 1;
    },
    setPriceRange: (
      state,
      action: PayloadAction<{ min: number; max: number }>,
    ) => {
      state.priceRange = action.payload;
      state.currentRequest.page = 1;
    },
    updateCourseDialog: (
      state,
      action: PayloadAction<{
        show: boolean;
        course: ApiCourse | null;
      }>,
    ) => {
      state.showCourseDialog = action.payload.show;
      state.courseToEdit = action.payload.course;
    },
    clearAllFilters: (state) => {
      state.selectedCategories = [];
      state.selectedCompany = null;
      state.currentRequest = {
        ...state.currentRequest,
        courseCategoryId: undefined,
        isPublished: undefined,
        page: 1,
      };
    },
  },
});

// Export actions
export const {
  setCurrentRequest,
  resetPagination,
  setSelectedCategories,
  setSelectedDurations,
  setSelectedCompany,
  setSelectedSession,
  setPriceRange,
  updateCourseDialog,
  clearAllFilters,
} = courseUISlice.actions;

// Selectors
export const selectCurrentRequest = (state: RootState) =>
  state.courseUI.currentRequest;
export const selectSelectedCategories = (state: RootState) =>
  state.courseUI.selectedCategories;
export const selectSelectedDurations = (state: RootState) =>
  state.courseUI.selectedDurations;
export const selectSelectedCompany = (state: RootState) =>
  state.courseUI.selectedCompany;
export const selectSelectedSession = (state: RootState) =>
  state.courseUI.selectedSession;
export const selectPriceRange = (state: RootState) => state.courseUI.priceRange;
export const selectShowCourseDialog = (state: RootState) =>
  state.courseUI.showCourseDialog;
export const selectCourseToEdit = (state: RootState) =>
  state.courseUI.courseToEdit;

export default courseUISlice.reducer;
