import { combineReducers } from "@reduxjs/toolkit";
import doNotMailReducer from "../modules/stochastic/features/DoNotMailList/slices/doNotMailSlice";
import stochasticCustomersReducer from "../modules/stochastic/features/CustomerList/slices/stochasticCustomersSlice";
import stochasticProspectsReducer from "../modules/stochastic/features/ProspectList/slices/stochasticProspectsSlice";
import userAppSettingsReducer from "../modules/hub/features/UserAppSettings/slices/userAppSettingsSlice";
import quickBooksReportsReducer from "../modules/hub/features/DocumentLibrary/slices/quickBooksReportsSlice";
import usersReducer from "../modules/hub/features/UserManagement/slices/usersSlice";
import companiesReducer from "../modules/hub/features/CompanyManagement/slices/companiesSlice";
import editUserDetailsReducer from "../modules/hub/features/UserManagement/slices/editUserDetailsSlice";
import companyProfileReducer from "../modules/hub/features/UserAppSettings/slices/companyProfileSlice";
import userProfileReducer from "../modules/hub/features/UserAppSettings/slices/userProfileSlice";
import campaignListReducer from "../modules/stochastic/features/CampaignManagement/slices/campaignListSlice";
import campaignReducer from "../modules/stochastic/features/CampaignManagement/slices/campaignSlice";
import campaignBatchListReducer from "../modules/stochastic/features/CampaignBatchManagement/slices/campaignBatchListSlice";
import batchProspectReducer from "../modules/stochastic/features/BatchProspectManagement/slices/batchProspectSlice";
import campaignFilesReducer from "../modules/stochastic/features/CampaignFileManagement/slices/campaignFilesSlice";
import createCampaignReducer from "../modules/stochastic/features/CampaignManagement/slices/createCampaignSlice";
import editRolesAndPermissionsReducer from "../modules/hub/features/UserManagement/slices/editRolesAndPermissionsSlice";
import coursesReducer from "../modules/trainingPortal/features/Courses/slices/coursesSlice";
import courseUIReducer from "../modules/trainingPortal/features/Courses/slices/courseUISlice";
import courseManagementReducer from "../modules/trainingPortal/features/CourseManagement/slices/courseManagementSlice";
import sessionsReducer from "../modules/trainingPortal/features/CourseManagement/slices/sessionsSlice";

const rootReducer = combineReducers({
  stochasticCustomers: stochasticCustomersReducer,
  stochasticProspects: stochasticProspectsReducer,
  doNotMail: doNotMailReducer,
  userAppSettings: userAppSettingsReducer,
  quickBooksReports: quickBooksReportsReducer,
  users: usersReducer,
  editUserDetails: editUserDetailsReducer,
  companies: companiesReducer,
  companyProfile: companyProfileReducer,
  userProfile: userProfileReducer,
  campaignList: campaignListReducer,
  campaign: campaignReducer,
  campaignBatchList: campaignBatchListReducer,
  batchProspect: batchProspectReducer,
  campaignFiles: campaignFilesReducer,
  createCampaign: createCampaignReducer,
  editRolesAndPermissions: editRolesAndPermissionsReducer,
  courses: coursesReducer,
  courseUI: courseUIReducer,
  courseManagement: courseManagementReducer,
  sessions: sessionsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
