import { useCallback } from "react";
import { useAppDispatch } from "@/app/hooks";
import { fetchCoursesAction } from "@/modules/trainingPortal/features/Courses/thunks/courseThunks";
import { setCurrentRequest } from "@/modules/trainingPortal/features/Courses/slices/courseUISlice";
import { useToastError } from "./useToastError";
import { CourseRequest, SortingParams, CourseFilters } from "../types/types";

export const useCourseManagementActions = (currentRequest: CourseRequest) => {
  const dispatch = useAppDispatch();
  const { executeToastError } = useToastError();

  const handlePaginationChange = useCallback(
    async (pageIndex: number, pageSize: number) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            page: pageIndex + 1,
            pageSize,
          }),
        );
        await dispatch(fetchCoursesAction()).unwrap();
      }, "Failed to change page");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleSortingChange = useCallback(
    async (params: SortingParams) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            sortBy: params.sortBy,
            sortOrder: params.sortOrder,
            page: 1,
          }),
        );
        await dispatch(fetchCoursesAction()).unwrap();
      }, "Failed to sort courses");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleSearchChange = useCallback(
    async (searchTerm: string) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            searchTerm,
            page: 1,
          }),
        );
        await dispatch(fetchCoursesAction()).unwrap();
      }, "Failed to search courses");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleFiltersChange = useCallback(
    async (filters: CourseFilters) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            courseCategoryId: filters.courseCategoryId ?? undefined,
            isPublished: filters.isPublished ?? undefined,
            page: 1,
          }),
        );
        await dispatch(fetchCoursesAction()).unwrap();
      }, "Failed to filter courses");
    },
    [dispatch, currentRequest, executeToastError],
  );

  return {
    handlePaginationChange,
    handleSortingChange,
    handleSearchChange,
    handleFiltersChange,
  };
};
