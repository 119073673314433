// Form imports
import { ControllerRenderProps } from "react-hook-form";
import { useState } from "react";

// UI Components
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";

// Types
import {
  CourseFormData,
  CourseFormFields,
} from "@/modules/trainingPortal/features/CourseManagement/schemas/courseSchema";
import { CourseFormFieldProps } from "@/modules/trainingPortal/features/CourseManagement/types/types";

// Hooks
import { useCourseFormField } from "../../hooks/useCourseFormField";

export const CourseFormField = ({
  control,
  name,
  label,
  placeholder,
  type = "text",
  id = `course-field-${name}`,
  disabled = false,
  readonly = false,
  validation,
  description,
}: CourseFormFieldProps): JSX.Element => {
  const { validatedType } = useCourseFormField({ control, name, type });
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormField
      control={control}
      name={name}
      render={({
        field,
      }: {
        field: ControllerRenderProps<CourseFormData, CourseFormFields>;
      }): JSX.Element => {
        const handleFocus = () => {
          if (!disabled && !readonly) {
            setIsFocused(true);
          }
        };

        const handleBlur = () => {
          setIsFocused(false);
          field.onBlur();
        };

        const handleChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
          if (!disabled && !readonly) {
            field.onChange(e);
          }
        };

        return (
          <FormItem>
            <FormLabel
              className={cn(
                disabled && "cursor-not-allowed opacity-50",
                isFocused && "text-primary",
              )}
              htmlFor={id}
            >
              {label}
            </FormLabel>
            <FormControl>
              {validatedType === "textarea" ? (
                <Textarea
                  className={cn(
                    disabled && "cursor-not-allowed",
                    readonly &&
                      "bg-muted focus-visible:ring-0 focus-visible:ring-offset-0",
                    isFocused && "border-secondary",
                  )}
                  disabled={disabled}
                  id={id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  placeholder={placeholder}
                  readOnly={readonly}
                  value={field.value || ""}
                />
              ) : (
                <Input
                  className={cn(
                    disabled && "cursor-not-allowed",
                    readonly &&
                      "bg-muted focus-visible:ring-0 focus-visible:ring-offset-0",
                    isFocused && "border-secondary",
                  )}
                  disabled={disabled}
                  id={id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  placeholder={placeholder}
                  readOnly={readonly}
                  type={validatedType}
                  value={field.value || ""}
                />
              )}
            </FormControl>
            {description && (
              <FormDescription className={cn(isFocused && "text-primary")}>
                {description}
              </FormDescription>
            )}
            <FormMessage />
          </FormItem>
        );
      }}
      rules={validation}
    />
  );
};
