import axios from "../axiosInstance";
import {
  FetchTotalSalesAgeDistributionChartDataRequest,
  FetchTotalSalesAgeDistributionChartDataResponse,
} from "./types";

export const fetchTotalSalesAgeDistributionChartData = async (
  requestData?: FetchTotalSalesAgeDistributionChartDataRequest,
): Promise<FetchTotalSalesAgeDistributionChartDataResponse> => {
  const response =
    await axios.get<FetchTotalSalesAgeDistributionChartDataResponse>(
      `/api/private/chart/total-sales-age-distribution`,
      { params: requestData },
    );

  return response.data;
};
