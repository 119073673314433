import { useState, useEffect, Fragment } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/rootReducer";
import {
  fetchCampaignAction,
  fetchCampaignStatusesAction,
  updateCampaignAction,
  clearCampaignData,
} from "../../slices/campaignSlice";
import { CampaignStatus } from "../../../../../../api/fetchCampaignStatuses/types";
import CampaignFileUploadComponent from "../CampaignFileUploadComponent/CampaignFileUploadComponent";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Modal from "react-modal";

interface EditCampaignDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  campaignId: number;
}

// Set the appElement for react-modal (do this once in your app's entry file)
Modal.setAppElement("#root");

const EditCampaignDrawer: React.FC<EditCampaignDrawerProps> = ({
  isOpen,
  onClose,
  onSave,
  campaignId,
}) => {
  const dispatch = useDispatch();

  const { campaign, statuses, loadingUpdate, errorFetch, errorUpdate } =
    useSelector((state: RootState) => state.campaign);

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState<string | null>("");
  const [endDate, setEndDate] = useState<string | null>("");
  const [statusId, setStatusId] = useState<number>(0);
  const [frequency, setFrequency] = useState<number | "">("");
  const [description, setDescription] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // State to control confirmation dialog for archiving
  const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchCampaignAction(campaignId));
      dispatch(fetchCampaignStatusesAction());
    }
  }, [isOpen, dispatch, campaignId]);

  useEffect(() => {
    if (campaign) {
      setName(campaign.name || "");
      setStartDate(campaign.startDate || "");
      setEndDate(campaign.endDate || "");
      setStatusId(campaign.campaignStatus?.id || 0);
      setFrequency(campaign.mailingIterationWeeks || "");
      setDescription(campaign.description || "");
      setPhoneNumber(campaign.phoneNumber || "");
    }
  }, [campaign]);

  const isFormValid = name.trim() !== "";

  const handleClose = () => {
    clearFormData();
    dispatch(clearCampaignData());
    onClose();
  };

  const handleSave = () => {
    // This function is called after the update is successful
    clearFormData();
    dispatch(clearCampaignData());
    onSave();
  };

  const clearFormData = () => {
    setName("");
    setStartDate("");
    setEndDate("");
    setStatusId(0);
    setFrequency("");
    setDescription("");
    setPhoneNumber("");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid) {
      return;
    }

    const selectedStatus = statuses.find((s) => s.id === statusId);
    if (selectedStatus && selectedStatus.name === "archived") {
      // Show confirmation modal before actually dispatching
      setShowArchiveConfirm(true);
    } else {
      // If not archiving, just update immediately
      submitUpdate();
    }
  };

  const submitUpdate = () => {
    const updateData = {
      name,
      description,
      phoneNumber: phoneNumber || undefined,
      status: statusId,
    };
    dispatch(updateCampaignAction(campaignId, updateData, handleSave));
  };

  const handleArchiveConfirm = () => {
    setShowArchiveConfirm(false);
    submitUpdate();
  };

  const handleArchiveCancel = () => {
    setShowArchiveConfirm(false);
    // Do not submit, just close the confirmation modal.
  };

  return (
    <>
      <Transition.Root as={Fragment} show={isOpen}>
        <Dialog className="relative z-30" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {/* Drawer overlay */}
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              {/* Drawer Panel */}
              <div className="fixed inset-y-0 right-0 flex max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <DialogPanel className="pointer-events-auto w-screen max-w-md">
                    <form
                      className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      onSubmit={handleSubmit}
                    >
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-primary dark:bg-secondary px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <DialogTitle className="text-base font-semibold text-white">
                              Edit Campaign
                            </DialogTitle>
                            <button
                              className="relative rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                              type="button"
                            >
                              <XMarkIcon
                                aria-hidden="true"
                                className="h-6 w-6"
                              />
                            </button>
                          </div>
                          <p className="text-sm text-white mt-1">
                            Edit the campaign details below.
                          </p>
                          {/* Display fetch error if any */}
                          {errorFetch && (
                            <p className="mt-2 text-sm text-red-500">
                              {errorFetch}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-1 flex-col justify-between px-4 sm:px-6 space-y-6 pb-5 pt-6">
                          {/* Campaign Name */}
                          <div>
                            <label
                              className="block text-sm font-medium text-gray-900"
                              htmlFor="campaign-name"
                            >
                              Campaign Name
                            </label>
                            <input
                              className="mt-2 block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                              id="campaign-name"
                              name="campaign-name"
                              onChange={(e) => setName(e.target.value)}
                              required
                              type="text"
                              value={name}
                            />
                          </div>
                          {/* Start Date */}
                          <div>
                            <label
                              className="block text-sm font-medium text-gray-900"
                              htmlFor="start-date"
                            >
                              Start Date
                            </label>
                            <input
                              className="mt-2 block w-full rounded-md border-0 p-2 bg-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm"
                              disabled
                              id="start-date"
                              name="start-date"
                              type="date"
                              value={startDate || ""}
                            />
                          </div>
                          {/* End Date */}
                          <div>
                            <label
                              className="block text-sm font-medium text-gray-900"
                              htmlFor="end-date"
                            >
                              End Date
                            </label>
                            <input
                              className="mt-2 block w-full rounded-md border-0 p-2 bg-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm"
                              disabled
                              id="end-date"
                              name="end-date"
                              type="date"
                              value={endDate || ""}
                            />
                          </div>
                          {/* Status */}
                          <div>
                            <label
                              className="block text-sm font-medium text-gray-900"
                              htmlFor="status"
                            >
                              Status
                            </label>
                            <select
                              className="mt-2 block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm"
                              id="status"
                              name="status"
                              onChange={(e) =>
                                setStatusId(Number(e.target.value))
                              }
                              value={statusId}
                            >
                              {statuses.map((status: CampaignStatus) => (
                                <option key={status.id} value={status.id}>
                                  {status.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* Frequency */}
                          <div>
                            <label
                              className="block text-sm font-medium text-gray-900"
                              htmlFor="frequency"
                            >
                              Mailing Frequency (Weeks)
                            </label>
                            <input
                              className="mt-2 block w-full rounded-md border-0 p-2 bg-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm"
                              disabled
                              id="frequency"
                              min={1}
                              name="frequency"
                              type="number"
                              value={frequency}
                            />
                          </div>
                          {/* Description */}
                          <div>
                            <label
                              className="block text-sm font-medium text-gray-900"
                              htmlFor="description"
                            >
                              Description
                            </label>
                            <textarea
                              className="mt-2 block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                              id="description"
                              name="description"
                              onChange={(e) => setDescription(e.target.value)}
                              rows={4}
                              value={description}
                            />
                          </div>
                          {/* Phone Number */}
                          <div>
                            <label
                              className="block text-sm font-medium text-gray-900"
                              htmlFor="phone-number"
                            >
                              Phone Number (Format: XXX-XXX-XXXX)
                            </label>
                            <input
                              className="mt-2 block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                              id="phone-number"
                              name="phone-number"
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              type="text"
                              value={phoneNumber}
                            />
                          </div>
                          {/* File Upload Component */}
                          <CampaignFileUploadComponent
                            campaignId={campaignId}
                          />
                          {errorUpdate && (
                            <p className="mt-2 text-sm text-red-500">
                              {errorUpdate}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-end px-4 py-4">
                        <button
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
                          onClick={handleClose}
                          type="button"
                        >
                          Cancel
                        </button>
                        <button
                          className={`ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus:outline-indigo-600 ${
                            !isFormValid || loadingUpdate
                              ? "bg-gray-400 text-gray-300 cursor-not-allowed"
                              : "bg-secondary dark:bg-primary text-white dark:hover:bg-primary-light hover:bg-secondary-light"
                          }`}
                          disabled={!isFormValid || loadingUpdate}
                          type="submit"
                        >
                          {loadingUpdate ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </form>
                  </DialogPanel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Archive Confirmation Modal with react-modal */}
      <Modal
        contentLabel="Archive Confirmation"
        isOpen={showArchiveConfirm}
        onRequestClose={handleArchiveCancel}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            borderRadius: "8px",
            padding: "24px",
            width: "300px",
            background: "white",
            boxShadow: "0 10px 25px rgba(0,0,0,.3)",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: 9999, // ensure above drawer
          },
        }}
      >
        <div className="flex flex-col items-center">
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="h-12 w-12 text-red-500"
          />
          <h3 className="mt-4 text-lg font-medium text-gray-900">
            Archive Campaign
          </h3>
          <p className="mt-2 text-sm text-gray-600 text-center">
            Are you sure you want to archive this campaign? Archiving a campaign
            will remove it from active listings and may not be easily
            reversible.
          </p>
        </div>
        <div className="mt-6 flex justify-center space-x-4">
          <button
            className="w-24 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
            onClick={handleArchiveCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            className="w-24 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700"
            onClick={handleArchiveConfirm}
            type="button"
          >
            Archive
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditCampaignDrawer;
