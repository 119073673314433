import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@/hooks/useToast";
import { CreateCourseRequest } from "@/api/createCourse/types";
import { EditCourseDTO } from "@/api/editCourse/types";
import { ApiCourse } from "@/api/fetchCourses/types";
import {
  createCourseWithCompanyCheckAction,
  editCourseAction,
  fetchCoursesAction,
} from "@/modules/trainingPortal/features/Courses/thunks/courseThunks";
import { setCurrentRequest } from "@/modules/trainingPortal/features/Courses/slices/courseUISlice";
import { RootState } from "@/app/rootReducer";
import { CourseFormData } from "../types/types";
import { DEFAULT_PAGE_SIZE } from "../constants/courseConstants";
export const useCourseForm = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { totalCount } = useSelector((state: RootState) => state.courses);
  const currentRequest = useSelector(
    (state: RootState) => state.courseUI.currentRequest,
  );

  const handleSubmit = async (
    data: CourseFormData,
    courseToEdit: ApiCourse | null,
  ) => {
    setIsSubmitting(true);
    try {
      if (courseToEdit) {
        const editCourseDTO: EditCourseDTO = {
          ...data,
          coursePrice: parseFloat(data.coursePrice.toFixed(2)),
          imageUrl: courseToEdit.imageUrl,
          isPublished: true,
        };

        const resultAction = await dispatch(
          editCourseAction({
            courseId: courseToEdit.id,
            editCourseDTO,
          }),
        );

        if (editCourseAction.fulfilled.match(resultAction)) {
          toast({
            title: "Success",
            description: "Course updated successfully",
          });
          // Fetch courses to update the list
          await dispatch(fetchCoursesAction());
        } else if (editCourseAction.rejected.match(resultAction)) {
          throw new Error(
            resultAction.error.message || "Failed to update course",
          );
        }
      } else {
        // Create course logic with pagination handling
        const courseData: Omit<CreateCourseRequest, "companyUuid"> = {
          ...data,
          coursePrice: parseFloat(data.coursePrice.toFixed(2)),
          hideFromCalendar: false,
          hideFromCatalog: false,
          isPublished: true,
          imageUrl: null,
          doceboCourseId: null,
          craftCourseId: null,
          courseTypeId: null,
          courseCategoryId: null,
          isEligibleForReturningStudent: null,
          isVoucherEligible: null,
          sgiVoucherValue: null,
        };

        const resultAction = await dispatch(
          createCourseWithCompanyCheckAction(courseData),
        );

        if (createCourseWithCompanyCheckAction.fulfilled.match(resultAction)) {
          // Calculate the last page
          const lastPage = Math.ceil(
            (totalCount + 1) / (currentRequest?.pageSize ?? DEFAULT_PAGE_SIZE),
          );

          // Update pagination to the last page
          await dispatch(
            setCurrentRequest({
              ...currentRequest,
              page: lastPage,
              // Keep other filters and sorting
              pageSize: currentRequest?.pageSize ?? DEFAULT_PAGE_SIZE,
            }),
          );

          // Fetch courses after pagination is updated
          await dispatch(fetchCoursesAction());

          toast({
            title: "Success",
            description: "Course created successfully",
          });
        } else if (
          createCourseWithCompanyCheckAction.rejected.match(resultAction)
        ) {
          throw new Error(
            resultAction.error.message || "Failed to create course",
          );
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
        variant: "destructive",
      });
      throw error; // Re-throw to allow parent components to handle the error
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit,
    isSubmitting,
  };
};
