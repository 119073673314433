import { createAsyncThunk } from "@reduxjs/toolkit";
import { createCourse } from "@/api/createCourse/createCourseApi";
import { editCourse } from "@/api/editCourse/editCourseApi";
import { copyCourse } from "@/api/copyCourse/copyCourseApi";
import { deleteCourse } from "@/api/deleteCourse/deleteCourseApi";
import { fetchCourses } from "@/api/fetchCourses/fetchCoursesApi";
import {
  CreateCourseRequest,
  CreateCourseResponse,
} from "@/api/createCourse/types";
import { EditCourseDTO } from "@/api/editCourse/types";
import { DeleteCourseResponse } from "@/api/deleteCourse/types";
import { FetchCoursesResponse, ApiCourse } from "@/api/fetchCourses/types";
import { RootState } from "@/app/rootReducer";
import { AppDispatch } from "@/app/store";

// Fetch courses thunk
export const fetchCoursesAction = createAsyncThunk<
  FetchCoursesResponse,
  void,
  { state: RootState; rejectValue: string }
>("courses/fetchCourses", async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const currentRequest = state.courseUI.currentRequest;
    const response = await fetchCourses(currentRequest);

    return response;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Create course thunk
export const createCourseAction = createAsyncThunk<
  CreateCourseResponse["data"],
  CreateCourseRequest,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("courses/createCourse", async (request, { rejectWithValue }) => {
  try {
    const response = await createCourse(request);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Edit course thunk
interface EditCourseActionPayload {
  courseId: number;
  editCourseDTO: EditCourseDTO;
  callback?: (updatedCourse: ApiCourse) => void;
}

export const editCourseAction = createAsyncThunk<
  ApiCourse,
  EditCourseActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "courses/editCourse",
  async ({ courseId, editCourseDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await editCourse(courseId, editCourseDTO);
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

// Duplicate course thunk
interface DuplicateCourseActionPayload {
  courseId: number;
  courseCode: string;
  courseName: string;
  callback?: (newCourse: ApiCourse) => void;
}

export const duplicateCourseAction = createAsyncThunk<
  ApiCourse,
  DuplicateCourseActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "courses/duplicateCourse",
  async ({ courseId, courseCode, courseName, callback }) => {
    const response = await copyCourse(courseId, { courseCode, courseName });
    if (callback) {
      callback(response.data);
    }
    return response.data;
  },
);

// Delete course thunk
export const deleteCourseAction = createAsyncThunk<
  DeleteCourseResponse["data"],
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("courses/deleteCourse", async (courseId, { rejectWithValue }) => {
  try {
    const response = await deleteCourse(courseId);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Create course with company check thunk
export const createCourseWithCompanyCheckAction = createAsyncThunk<
  CreateCourseResponse["data"],
  Omit<CreateCourseRequest, "companyUuid">,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "courses/createCourseWithCompany",
  async (courseData, { dispatch, rejectWithValue }) => {
    try {
      const companyUuid = localStorage.getItem("selectedCompanyUuid");
      if (!companyUuid) {
        return rejectWithValue("No company selected");
      }

      const fullCourseData: CreateCourseRequest = {
        ...courseData,
        companyUuid,
        hideFromCalendar: false,
        hideFromCatalog: false,
        isPublished: true,
        imageUrl: null,
        doceboCourseId: null,
        craftCourseId: null,
        courseTypeId: null,
        courseCategoryId: null,
        isEligibleForReturningStudent: null,
        isVoucherEligible: null,
        sgiVoucherValue: null,
      };

      return await dispatch(createCourseAction(fullCourseData)).unwrap();
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);
