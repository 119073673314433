import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/Card/Card";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { useTheme } from "@/context/ThemeContext";
import { TotalSalesByYearAndMonthChartDataItem } from "@/api/fetchTotalSalesByYearAndMonthChartData/types";

type AlphaChartProps = {
  chartId?: string;
  initialData: TotalSalesByYearAndMonthChartDataItem[];
};

export default function TotalSalesByYearAndMonthChart({
  initialData,
}: AlphaChartProps) {
  const { theme } = useTheme();

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const YEAR_COLORS = {
    "2017": { light: "#6366F1", dark: "#818CF8" },
    "2018": { light: "#FCD34D", dark: "#FBBF24" },
    "2019": { light: "#92400E", dark: "#B45309" },
    "2020": { light: "#EF4444", dark: "#F87171" },
    "2021": { light: "#3B82F6", dark: "#60A5FA" },
    "2022": { light: "#22C55E", dark: "#34D399" },
    "2023": { light: "#F97316", dark: "#FB923C" },
    "2024": { light: "#6B7280", dark: "#9CA3AF" },
  };

  return (
    <Card className="w-full bg-white dark:bg-secondary-dark">
      <CardHeader>
        <CardTitle className="text-fontColor dark:text-light">
          Total Sales By Year & Month Chart
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer height={500} width="100%">
          <BarChart
            data={initialData}
            margin={{
              top: 20,
              right: 30,
              left: 45,
              bottom: 5,
            }}
          >
            <CartesianGrid
              stroke={theme === "dark" ? "#374151" : "#e5e7eb"}
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey="month"
              stroke={theme === "dark" ? "#9CA3AF" : "#4B5563"}
            />
            <YAxis
              stroke={theme === "dark" ? "#9CA3AF" : "#4B5563"}
              tickFormatter={formatCurrency}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme === "dark" ? "#1F2937" : "#FFFFFF",
                borderColor: theme === "dark" ? "#374151" : "#E5E7EB",
                color: theme === "dark" ? "#F3F4F6" : "#1F2937",
              }}
              formatter={formatCurrency}
            />
            <Legend />
            {Object.keys(YEAR_COLORS).map((year) => (
              <Bar
                dataKey={year}
                fill={
                  YEAR_COLORS[year as keyof typeof YEAR_COLORS][
                    theme === "dark" ? "dark" : "light"
                  ]
                }
                key={year}
                name={year}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
