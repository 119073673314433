import { Button } from "@/components/ui/button";
import { TablePaginationProps } from "@/modules/trainingPortal/features/CourseManagement/types/types";
export const CourseManagementPagination: React.FC<TablePaginationProps> = ({
  totalCount,
  pageIndex,
  pageSize,
  onPageChange,
}) => (
  <div className="flex items-center justify-between px-2">
    <div className="flex-1 text-sm text-muted-foreground">
      <span className="font-medium">{totalCount}</span> courses total, page{" "}
      <span className="font-medium">{pageIndex + 1}</span> of{" "}
      <span className="font-medium">{Math.ceil(totalCount / pageSize)}</span>
    </div>
    <div className="flex items-center space-x-6 lg:space-x-8">
      <div className="flex items-center space-x-2">
        <p className="text-sm font-medium">Courses per page</p>
        <select
          className="h-9 w-[70px] rounded-md border border-input bg-transparent px-3 py-1 text-sm"
          onChange={(e) => onPageChange(0, Number(e.target.value))}
          value={pageSize}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center space-x-2">
        <Button
          className="h-9 px-4"
          disabled={pageIndex === 0}
          onClick={() => onPageChange(pageIndex - 1, pageSize)}
          size="sm"
          variant="outline"
        >
          Previous
        </Button>
        <Button
          className="h-9 px-4"
          disabled={pageIndex === Math.ceil(totalCount / pageSize) - 1}
          onClick={() => onPageChange(pageIndex + 1, pageSize)}
          size="sm"
          variant="outline"
        >
          Next
        </Button>
      </div>
    </div>
  </div>
);
