import axios from "../axiosInstance";
import { CopyCourseRequest, CopyCourseResponse } from "./types";

export const copyCourse = async (
  courseId: number,
  requestData: CopyCourseRequest,
): Promise<CopyCourseResponse> => {
  const response = await axios.post<CopyCourseResponse>(
    `/api/private/courses/${courseId}/duplicate`,
    requestData,
  );
  return response.data;
};
