import React from "react";
import { Button } from "@/components/ui/button";
import { PlusCircle, Loader2 } from "lucide-react";
import CourseManagementSearch from "../CourseManagementSearch/CourseManagementSearch";
import CourseManagementFilters from "../CourseManagementFilters/CourseManagementFilters";
import { CourseManagementToolbarProps } from "../../types/types";

export const CourseManagementToolbar: React.FC<
  CourseManagementToolbarProps
> = ({ isLoading, onSearch, onFilterChange, onAddCourse }) => (
  <div className="flex items-center gap-4">
    <div className="flex-1">
      <CourseManagementSearch onSearch={onSearch} />
    </div>
    <CourseManagementFilters onFilterChange={onFilterChange} />
    <Button
      className="h-10 min-w-[120px] px-4 py-2 bg-primary text-white hover:bg-primary/90"
      disabled={isLoading}
      onClick={onAddCourse}
    >
      {isLoading ? (
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
      ) : (
        <PlusCircle className="mr-2 h-4 w-4" />
      )}
      Add Course
    </Button>
  </div>
);
