// React and form imports
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// UI Components
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary";

// Icons
import { Loader2 } from "lucide-react";

// Feature imports
import { CourseFormField } from "@/modules/trainingPortal/features/CourseManagement/components/CourseFormField/CourseFormField";
import {
  courseSchema,
  CourseFormData,
} from "@/modules/trainingPortal/features/CourseManagement/schemas/courseSchema";
import { COURSE_FORM } from "@/modules/trainingPortal/features/CourseManagement/constants/courseConstants";
import { CourseDialogProps } from "@/modules/trainingPortal/features/CourseManagement/types/types";
const CourseDialog = ({
  open,
  onOpenChange,
  onSubmit,
  initialData,
  mode,
  isSubmitting: propsIsSubmitting,
}: CourseDialogProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(propsIsSubmitting ?? false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);

  // Store the initial data in a ref to prevent re-renders
  const initialDataRef = useRef(initialData);

  const form = useForm<CourseFormData>({
    resolver: zodResolver(courseSchema),
    defaultValues: {
      courseName:
        initialDataRef.current?.courseName ||
        COURSE_FORM.DEFAULT_VALUES.courseName,
      courseCode:
        initialDataRef.current?.courseCode ||
        COURSE_FORM.DEFAULT_VALUES.courseCode,
      courseDescription:
        initialDataRef.current?.courseDescription ||
        COURSE_FORM.DEFAULT_VALUES.courseDescription,
      coursePrice:
        initialDataRef.current?.coursePrice ||
        COURSE_FORM.DEFAULT_VALUES.coursePrice,
    },
  });

  useEffect(() => {
    if (open) {
      initialDataRef.current = initialData;
    }
  }, [open, initialData]);

  useEffect((): void => {
    if (open && mode === "add") {
      form.reset(COURSE_FORM.DEFAULT_VALUES);
    } else if (open && mode === "edit" && initialDataRef.current) {
      form.reset(initialDataRef.current);
    }
  }, [open, mode, form]);

  const isDirty = form.formState.isDirty;

  const handleSubmit = async (data: CourseFormData): Promise<void> => {
    if (!isDirty) {
      onOpenChange(false);
      return;
    }

    try {
      setIsSubmitting(true);
      setSubmitError(null);
      await onSubmit(data);
      onOpenChange(false);
    } catch (error) {
      setSubmitError(
        error instanceof Error
          ? error.message
          : COURSE_FORM.ERROR_MESSAGES.default,
      );
      console.error("Form submission error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = (): void => {
    if (isDirty) {
      setShowUnsavedChangesDialog(true);
    } else {
      onOpenChange(false);
    }
  };

  const handleConfirmClose = (): void => {
    form.reset();
    setShowUnsavedChangesDialog(false);
    onOpenChange(false);
  };

  const handleCancelClose = (): void => {
    setShowUnsavedChangesDialog(false);
  };

  const handleReset = (): void => {
    if (isDirty) {
      setShowResetDialog(true);
    }
  };

  const handleConfirmReset = (): void => {
    if (mode === "add") {
      form.reset(COURSE_FORM.DEFAULT_VALUES);
    } else if (initialData) {
      form.reset(initialData);
    }
    setShowResetDialog(false);
  };

  const handleCancelReset = (): void => {
    setShowResetDialog(false);
  };

  return (
    <ErrorBoundary>
      <AlertDialog
        onOpenChange={setShowUnsavedChangesDialog}
        open={showUnsavedChangesDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {COURSE_FORM.TITLES.unsavedChanges}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {COURSE_FORM.MESSAGES.unsavedChanges}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelClose}>
              {COURSE_FORM.BUTTON_TEXT.cancel}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmClose}>
              {COURSE_FORM.BUTTON_TEXT.discard}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog onOpenChange={setShowResetDialog} open={showResetDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{COURSE_FORM.TITLES.resetForm}</AlertDialogTitle>
            <AlertDialogDescription>
              {COURSE_FORM.MESSAGES.resetForm}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelReset}>
              {COURSE_FORM.BUTTON_TEXT.cancel}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmReset}>
              {COURSE_FORM.BUTTON_TEXT.reset}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Sheet
        onOpenChange={(isOpen) => {
          if (!isOpen && !isSubmitting) {
            handleClose();
          }
        }}
        open={open}
      >
        <SheetContent className="sm:max-w-[600px]">
          <SheetHeader>
            <SheetTitle>
              {mode === "add"
                ? COURSE_FORM.TITLES.add
                : COURSE_FORM.TITLES.edit}
            </SheetTitle>
          </SheetHeader>

          <Form {...form}>
            <form
              className="space-y-4 mt-4"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <CourseFormField
                control={form.control}
                label={COURSE_FORM.LABELS.courseName}
                name="courseName"
                placeholder={COURSE_FORM.PLACEHOLDERS.courseName}
              />

              <CourseFormField
                control={form.control}
                label={COURSE_FORM.LABELS.courseCode}
                name="courseCode"
                placeholder={COURSE_FORM.PLACEHOLDERS.courseCode}
              />

              <CourseFormField
                control={form.control}
                label={COURSE_FORM.LABELS.courseDescription}
                name="courseDescription"
                placeholder={COURSE_FORM.PLACEHOLDERS.courseDescription}
                type="textarea"
              />

              <CourseFormField
                control={form.control}
                label={COURSE_FORM.LABELS.coursePrice}
                name="coursePrice"
                placeholder={COURSE_FORM.PLACEHOLDERS.coursePrice}
                type="number"
              />

              {submitError && (
                <Alert variant="destructive">
                  <AlertTitle>{COURSE_FORM.ERROR_MESSAGES.title}</AlertTitle>
                  <AlertDescription>{submitError}</AlertDescription>
                </Alert>
              )}

              <div className="flex justify-between space-x-4 pt-4">
                <Button
                  disabled={isSubmitting || !isDirty}
                  onClick={handleReset}
                  type="button"
                  variant="outline"
                >
                  {COURSE_FORM.BUTTON_TEXT.reset}
                </Button>
                <div className="flex space-x-4">
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    type="button"
                    variant="outline"
                  >
                    {COURSE_FORM.BUTTON_TEXT.cancel}
                  </Button>
                  <Button
                    className="min-w-[100px]"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        {mode === "add" ? "Creating..." : "Updating..."}
                      </>
                    ) : mode === "add" ? (
                      "Create"
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        </SheetContent>
      </Sheet>
    </ErrorBoundary>
  );
};

export default CourseDialog;
