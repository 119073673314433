import React from "react";
import { Input } from "../../components/ui/input";
import { Search } from "lucide-react";

interface TPSearchProps {
  className?: string;
  onSearchChange: (value: string) => void;
  placeholder?: string;
  searchQuery: string;
}

const TPSearch: React.FC<TPSearchProps> = ({
  className = "",
  onSearchChange,
  placeholder = "Search...",
  searchQuery,
}) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
  };

  return (
    <div className={`relative ${className}`}>
      <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        className="pl-8"
        onChange={handleSearchChange}
        placeholder={placeholder}
        value={searchQuery}
      />
    </div>
  );
};

export default TPSearch;
