import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/rootReducer";
import { selectAllCourses } from "@/modules/trainingPortal/features/Courses/slices/coursesSlice";
import {
  selectShowCourseDialog,
  selectCourseToEdit,
} from "@/modules/trainingPortal/features/Courses/slices/courseUISlice";
import { FilterOption, FilterType } from "../types/types";

export const selectCourseManagementState = (state: RootState) => state.courses;
export const selectCourseUIState = (state: RootState) => state.courseUI;

export const selectCoursesWithLoadingState = createSelector(
  [selectAllCourses, selectCourseManagementState],
  (courses, courseState) => ({
    courses,
    isLoadingCourses: courseState.loading,
    coursesError: courseState.error,
    totalCount: courseState.totalCount,
  }),
);

export const selectCourseDialogState = createSelector(
  [selectShowCourseDialog, selectCourseToEdit],
  (showDialog, courseToEdit) => ({
    showCourseDialog: showDialog,
    courseToEdit,
  }),
);

export const selectCurrentRequestState = createSelector(
  [selectCourseUIState],
  (courseUI) => courseUI.currentRequest,
);

export const selectCategoryOptions = createSelector(
  [selectAllCourses],
  (courses) => {
    const categories = Array.from(
      new Set(
        courses
          .map((course) => course.courseCategoryId)
          .filter((id): id is number => id !== null && id !== undefined),
      ),
    );

    return categories.map(
      (categoryId): FilterOption => ({
        label: `Category ${categoryId}`,
        value: String(categoryId),
      }),
    );
  },
);

export const selectSelectedFilters = createSelector(
  [selectCurrentRequestState],
  (currentRequest): Record<FilterType, string[]> => ({
    category: currentRequest.courseCategoryId
      ? [String(currentRequest.courseCategoryId)]
      : [],
    status: currentRequest.isPublished ? ["Published"] : [],
  }),
);

export const selectSelectedCourseIds = (state: RootState) =>
  state.courses.selectedIds;
