import { createContext, useContext } from "react";
import { CourseManagementContextType } from "../types/types";

export const CourseManagementContext = createContext<
  CourseManagementContextType | undefined
>(undefined);

export const useCourseManagement = () => {
  const context = useContext(CourseManagementContext);
  if (!context) {
    throw new Error(
      "useCourseManagement must be used within CourseManagementProvider",
    );
  }
  return context;
};
