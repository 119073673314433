import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { MoreHorizontal, Edit, Copy, Trash2 } from "lucide-react";
import { ApiCourse } from "@/api/fetchCourses/types";

interface ActionMenuProps {
  course: ApiCourse;
  onEdit: (course: ApiCourse) => void;
  onDuplicate: (course: ApiCourse) => void;
  onDelete: (courseId: number) => void;
}

export const CourseManagementActionMenu: React.FC<ActionMenuProps> = ({
  course,
  onEdit,
  onDuplicate,
  onDelete,
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button
        className="h-8 w-8 p-0 hover:bg-gray-200 transition-colors"
        variant="ghost"
      >
        <span className="sr-only">Open menu</span>
        <MoreHorizontal className="h-4 w-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" className="w-[160px] p-2 bg-white">
      <DropdownMenuItem
        className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
        onClick={() => onEdit(course)}
      >
        <Edit className="mr-2 h-4 w-4" />
        <span>Edit</span>
      </DropdownMenuItem>
      <DropdownMenuItem
        className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
        onClick={() => onDuplicate(course)}
      >
        <Copy className="mr-2 h-4 w-4" />
        <span>Duplicate</span>
      </DropdownMenuItem>
      <DropdownMenuItem
        className="flex items-center px-3 py-2 text-sm cursor-pointer text-red-600 hover:bg-gray-100 transition-colors"
        onClick={() => onDelete(course.id)}
      >
        <Trash2 className="mr-2 h-4 w-4" />
        <span>Delete</span>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);
