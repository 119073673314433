import { useState, useEffect } from "react";
import {
  FetchTotalSalesAgeDistributionChartDataRequest,
  FetchTotalSalesAgeDistributionChartDataResponse,
} from "@/api/fetchTotalSalesAgeDistributionChartData/types";
import { fetchTotalSalesAgeDistributionChartData } from "@/api/fetchTotalSalesAgeDistributionChartData/fetchTotalSalesAgeDistributionChartDataApi";

export function useTotalSalesAgeDistributionChartData(
  requestData?: FetchTotalSalesAgeDistributionChartDataRequest,
) {
  const [chartData, setChartData] =
    useState<FetchTotalSalesAgeDistributionChartDataResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    fetchTotalSalesAgeDistributionChartData(requestData)
      .then((data: FetchTotalSalesAgeDistributionChartDataResponse) => {
        setChartData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [requestData]);

  return {
    chartData,
    loading,
    error,
  };
}
