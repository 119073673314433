import React, { useCallback } from "react";
import CourseManagementTable from "../CourseManagementTable/CourseManagementTable";
import {
  SortOrder,
  CourseManagementTableSectionProps,
} from "../../types/types";

export const CourseManagementTableSection: React.FC<
  CourseManagementTableSectionProps
> = ({
  courses,
  isLoading,
  currentRequest,
  totalCount,
  onEditCourse,
  onPaginationChange,
  onSortingChange,
  defaultPageSize,
}) => {
  const handleSortingChange = useCallback(
    (sortBy?: string, sortOrder?: SortOrder) =>
      onSortingChange({ sortBy, sortOrder }),
    [onSortingChange],
  );

  return (
    <CourseManagementTable
      data={courses}
      isLoading={isLoading}
      onEditCourse={onEditCourse}
      onPaginationChange={onPaginationChange}
      onSortingChange={handleSortingChange}
      pagination={{
        pageIndex: (currentRequest.page ?? 1) - 1,
        pageSize: currentRequest.pageSize ?? defaultPageSize,
        totalCount: totalCount ?? 0,
      }}
      sorting={{
        sortBy: currentRequest.sortBy,
        sortOrder: currentRequest.sortOrder,
      }}
    />
  );
};

CourseManagementTableSection.displayName = "CourseManagementTableSection";

export default React.memo(CourseManagementTableSection);
