import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ApiCourse } from "@/api/fetchCourses/types";
import { RootState } from "@/app/rootReducer";
import {
  fetchCoursesAction,
  createCourseAction,
  editCourseAction,
  duplicateCourseAction,
  deleteCourseAction,
} from "../thunks/courseThunks";

// Create entity adapter for courses
const coursesAdapter = createEntityAdapter<ApiCourse>();

// Get the selectors
const selectors = coursesAdapter.getSelectors<RootState>(
  (state) => state.courses,
);

// Export selectors
export const {
  selectAll: selectAllCourses,
  selectById: selectCourseById,
  selectIds: selectCourseIds,
} = selectors;

export const selectTotalCount = (state: RootState) => state.courses.totalCount;

// Define the state shape for course data
interface CoursesState {
  loading: boolean;
  error: string | null;
  totalCount: number;
  selectedIds: number[];
}

// Initial state using the adapter
const initialState = coursesAdapter.getInitialState<CoursesState>({
  loading: false,
  error: null,
  totalCount: 0,
  selectedIds: [],
});

// Create the slice
const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    toggleCourseSelection: (state, action: PayloadAction<number>) => {
      const courseId = action.payload;
      const index = state.selectedIds.indexOf(courseId);
      if (index === -1) {
        state.selectedIds.push(courseId);
      } else {
        state.selectedIds.splice(index, 1);
      }
    },
    setSelectedCourses: (state, action: PayloadAction<number[]>) => {
      state.selectedIds = action.payload;
    },
    clearSelectedCourses: (state) => {
      state.selectedIds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch courses
      .addCase(fetchCoursesAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoursesAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.totalCount = action.payload.meta?.totalCount || 0;
        coursesAdapter.setAll(state, action.payload.data.items);
      })
      .addCase(fetchCoursesAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch courses";
      })
      // Create course
      .addCase(createCourseAction.fulfilled, (state, action) => {
        if (!action.payload.id) {
          return; // Skip adding if id is null
        }

        // Get the original request from meta arg
        const request = action.meta.arg;

        const course: ApiCourse = {
          id: action.payload.id,
          courseCode: request.courseCode,
          courseName: action.payload.courseName || "",
          courseDescription: action.payload.courseDescription || "",
          coursePrice: action.payload.coursePrice || 0,
          imageUrl: action.payload.imageUrl || null,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          courseCategoryId: action.payload.courseCategoryId || null,
        };
        coursesAdapter.addOne(state, course);
      })
      // Edit course
      .addCase(editCourseAction.fulfilled, (state, action) => {
        coursesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      // Duplicate course
      .addCase(duplicateCourseAction.fulfilled, (state, action) => {
        if (action.payload && action.payload.id) {
          coursesAdapter.addOne(state, action.payload);
        }
      })
      // Delete course
      .addCase(deleteCourseAction.fulfilled, (state, action) => {
        coursesAdapter.removeOne(state, action.payload.courseId);
      });
  },
});

// Export actions
export const {
  toggleCourseSelection,
  setSelectedCourses,
  clearSelectedCourses,
} = coursesSlice.actions;

export default coursesSlice.reducer;
