import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignsAction } from "../slices/campaignListSlice";
import {
  SortingState,
  PaginationState,
  OnChangeFn,
  VisibilityState,
} from "@tanstack/react-table";
import { RootState } from "../../../../../app/rootReducer";
import { FetchCompanyCampaignsRequest } from "../../../../../api/fetchCompanyCampaigns/types";

export function useCampaigns() {
  const dispatch = useDispatch();
  const { campaigns, totalCount, loading, error } = useSelector(
    (state: RootState) => state.campaignList,
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [filters, setFilters] = useState<{ name?: string }>({});

  // Add campaignStatusId here so changes trigger re-fetch
  const [campaignStatusId, setCampaignStatusId] = useState<number | undefined>(
    1,
  );

  // Construct requestData from current state
  const requestData = useMemo<FetchCompanyCampaignsRequest>(() => {
    return {
      page: pagination.pageIndex + 1,
      perPage: pagination.pageSize,
      sortOrder:
        sorting.length > 0 ? (sorting[0].desc ? "DESC" : "ASC") : "ASC",
      // Include campaignStatusId in the request
      campaignStatusId,
    };
  }, [pagination, sorting, campaignStatusId]);

  // Single effect for fetching campaigns whenever requestData changes
  useEffect(() => {
    dispatch(fetchCampaignsAction(requestData));
  }, [dispatch, requestData]);

  const handlePaginationChange: OnChangeFn<PaginationState> = useCallback(
    (updaterOrValue) => {
      setPagination((old) =>
        typeof updaterOrValue === "function"
          ? updaterOrValue(old)
          : updaterOrValue,
      );
    },
    [],
  );

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      setSorting((old) =>
        typeof updaterOrValue === "function"
          ? updaterOrValue(old)
          : updaterOrValue,
      );
    },
    [],
  );

  const handleColumnVisibilityChange: OnChangeFn<VisibilityState> = useCallback(
    (updaterOrValue) => {
      setColumnVisibility((old) =>
        typeof updaterOrValue === "function"
          ? updaterOrValue(old)
          : updaterOrValue,
      );
    },
    [],
  );

  const handleFilterChange = useCallback((name: string) => {
    setFilters({ name });
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, []);

  // New function to change campaignStatusId and reset pagination
  const handleCampaignStatusChange = useCallback((newStatusId?: number) => {
    setCampaignStatusId(newStatusId);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, []);

  return {
    campaigns,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    filters,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleFilterChange,
    handleCampaignStatusChange, // expose this function
    requestData,
    campaignStatusId, // expose current status id if needed
  };
}
