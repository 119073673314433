import { useToast } from "@/hooks/useToast";

export const useToastError = () => {
  const { toast } = useToast();

  const executeToastError = async <T>(
    action: () => Promise<T>,
    errorMessage: string,
  ): Promise<T | undefined> => {
    try {
      return await action();
    } catch {
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
    }
  };

  return { executeToastError };
};
