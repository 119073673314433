import { axiosInstance } from "../axiosInstance";
import { EditCourseResponse, EditCourseDTO } from "./types";

export const editCourse = async (
  id: number,
  editCourseDTO: EditCourseDTO,
): Promise<EditCourseResponse> => {
  const impersonateUserUuid = localStorage.getItem("impersonateUserUuid");

  const headers: Record<string, string> = {};

  if (impersonateUserUuid) {
    headers["X-Impersonate-User-UUID"] = impersonateUserUuid;
    console.log(`Impersonating user: ${impersonateUserUuid}`);
  }

  const response = await axiosInstance.put<EditCourseResponse>(
    `/api/private/courses/${id}/edit`,
    editCourseDTO,
    { headers },
  );
  return response.data;
};
