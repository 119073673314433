import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../app/rootReducer";
import { Session, initializeCourseSessions } from "./sessionsSlice";

export interface Course {
  id: string;
  title: string;
  instructor: string;
  category: string;
  duration: string;
  capacity: number;
  status: "active" | "inactive" | "archived";
  nextSession?: string;
}

export interface CourseWithSessions extends Course {
  sessions?: Session[];
}

interface CourseManagementState {
  courses: Course[];
  selectedCourses: string[];
  filters: {
    category: string[];
    status: string[];
  };
  searchQuery: string;
  pagination: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
  };
}

// Mock data generator
const generateMockCourses = (): Course[] => {
  const categories = [
    "Development",
    "Design",
    "Marketing",
    "Management",
    "Data Science",
  ];
  const mockCourses: Course[] = [];

  for (let i = 0; i < 50; i++) {
    const category = categories[Math.floor(Math.random() * categories.length)];
    mockCourses.push({
      id: `course-${i + 1}`,
      title: `Course ${i + 1}`,
      instructor: `Instructor ${i + 1}`,
      category,
      duration: `${Math.floor(Math.random() * 5 + 1)} days`,
      capacity: Math.floor(Math.random() * 20 + 10),
      status: Math.random() > 0.3 ? "active" : "inactive",
      nextSession:
        Math.random() > 0.5
          ? new Date(Date.now() + Math.random() * 7776000000)
              .toISOString()
              .split("T")[0]
          : undefined,
    });
  }
  return mockCourses;
};

const initialState: CourseManagementState = {
  courses: generateMockCourses(),
  selectedCourses: [],
  filters: {
    category: [],
    status: [],
  },
  searchQuery: "",
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
  },
};

export const updateCourse = createAsyncThunk(
  "courseManagement/updateCourse",
  async (courseData: Course) => {
    // TODO: Replace with actual API call
    return courseData;
  },
);

export const updateCourseStatus = createAsyncThunk(
  "courseManagement/updateCourseStatus",
  async ({
    courseIds,
    status,
  }: {
    courseIds: string[];
    status: Course["status"];
  }) => {
    // TODO: Replace with actual API call
    return { courseIds, status };
  },
);

export const duplicateCourse = createAsyncThunk(
  "courseManagement/duplicateCourse",
  async (courseId: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const courseToDuplicate = state.courseManagement.courses.find(
      (c) => c.id === courseId,
    );

    if (!courseToDuplicate) {
      throw new Error("Course not found");
    }

    // Create a new course object with a new ID and modified title
    const newCourse: Course = {
      ...courseToDuplicate,
      id: `${courseId}_copy_${Date.now()}`,
      title: `${courseToDuplicate.title} (Copy)`,
      status: "inactive", // Start as inactive
    };

    dispatch(initializeCourseSessions(newCourse.id));

    // TODO: Replace with actual API call
    return newCourse;
  },
);

export const deleteCourse = createAsyncThunk(
  "courseManagement/deleteCourse",
  async (courseId: string) => {
    // TODO: Replace with actual API call
    return courseId;
  },
);

const courseManagementSlice = createSlice({
  name: "courseManagement",
  initialState,
  reducers: {
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
      state.pagination.currentPage = 1;
    },
    toggleCourseSelection(state, action: PayloadAction<string>) {
      const index = state.selectedCourses.indexOf(action.payload);
      if (index === -1) {
        state.selectedCourses.push(action.payload);
      } else {
        state.selectedCourses.splice(index, 1);
      }
    },
    setSelectedCourses(state, action: PayloadAction<string[]>) {
      state.selectedCourses = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.pagination.currentPage = action.payload;
    },
    setItemsPerPage(state, action: PayloadAction<number>) {
      state.pagination.itemsPerPage = action.payload;
    },
    toggleFilter(
      state,
      action: PayloadAction<{ type: "category" | "status"; value: string }>,
    ) {
      const { type, value } = action.payload;
      const index = state.filters[type].indexOf(value);
      if (index === -1) {
        state.filters[type].push(value);
      } else {
        state.filters[type].splice(index, 1);
      }
      state.pagination.currentPage = 1;
    },
    clearFilters(state) {
      state.filters = {
        category: [],
        status: [],
      };
      state.pagination.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCourseStatus.fulfilled, (state, action) => {
        const { courseIds, status } = action.payload;
        courseIds.forEach((courseId) => {
          const course = state.courses.find((c) => c.id === courseId);
          if (course) {
            course.status = status;
          }
        });
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        const updatedCourse = action.payload;
        const index = state.courses.findIndex((c) => c.id === updatedCourse.id);
        if (index !== -1) {
          state.courses[index] = updatedCourse;
        }
      })
      .addCase(duplicateCourse.fulfilled, (state, action) => {
        state.courses.push(action.payload);
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        const courseId = action.payload;
        state.courses = state.courses.filter(
          (course) => course.id !== courseId,
        );
      });
  },
});

export const { setSearchQuery, setItemsPerPage, setCurrentPage, toggleFilter } =
  courseManagementSlice.actions;

// Selectors
export const selectCourses = (state: RootState) =>
  state.courseManagement.courses;
export const selectSelectedCourses = (state: RootState) =>
  state.courseManagement.selectedCourses;
export const selectSearchQuery = (state: RootState) =>
  state.courseManagement.searchQuery;
export const selectPagination = (state: RootState) =>
  state.courseManagement.pagination;
export const selectFilters = (state: RootState) =>
  state.courseManagement.filters;

export const selectFilteredCourses = (state: RootState) => {
  const courses = selectCourses(state);
  const searchQuery = selectSearchQuery(state);
  const filters = selectFilters(state);

  return courses.filter((course) => {
    const matchesSearch = searchQuery
      ? Object.values(course).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : true;

    const matchesCategory =
      filters.category.length === 0 ||
      filters.category.includes(course.category);

    const matchesStatus =
      filters.status.length === 0 || filters.status.includes(course.status);

    return matchesSearch && matchesCategory && matchesStatus;
  });
};

export const selectTotalCount = (state: RootState) =>
  state.courseManagement.pagination.totalItems;

export default courseManagementSlice.reducer;
