import { FetchCoursesRequest, FetchCoursesResponse } from "./types";
import { axiosInstance } from "../axiosInstance";

export const fetchCourses = async (
  requestData: FetchCoursesRequest,
): Promise<FetchCoursesResponse> => {
  const impersonateUserUuid = localStorage.getItem("impersonateUserUuid");

  const headers: Record<string, string> = {};

  if (impersonateUserUuid) {
    headers["X-Impersonate-User-UUID"] = impersonateUserUuid;
    console.log(`Impersonating user: ${impersonateUserUuid}`);
  }

  const params = {
    ...requestData,
  };

  const response = await axiosInstance.get<FetchCoursesResponse>(
    "/api/private/courses",
    {
      headers,
      params,
    },
  );
  return response.data;
};
