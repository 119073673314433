import { HomeIcon } from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";
import { UserRoundPlus } from "lucide-react";

const trainingPortalNavigation: NavigationItem[] = [
  {
    name: "Dashboard",
    href: "/training-portal",
    icon: HomeIcon,
    current: false,
    internalName: "dashboard",
    permissions: [],
  },
  {
    name: "Admin",
    href: "/training-portal/admin",
    icon: HomeIcon,
    current: false,
    internalName: "admin",
    permissions: [],
    children: [
      {
        name: "Course Management",
        href: "/training-portal/admin/course-management",
        icon: UserRoundPlus,
        current: false,
        internalName: "course-management",
        permissions: [],
      },
    ],
  },
];

export default trainingPortalNavigation;
