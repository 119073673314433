import { inputTypeSchema } from "../schemas/courseSchema";
import {
  UseCourseFormFieldProps,
  UseCourseFormFieldReturn,
} from "../types/types";

export const useCourseFormField = ({
  type = "text",
}: UseCourseFormFieldProps): UseCourseFormFieldReturn => {
  // Validate type prop
  const validatedType = inputTypeSchema.parse(type);

  return {
    validatedType,
  };
};
