import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../app/rootReducer";

export interface Session {
  id: string;
  courseId: string;
  startDate: string;
  endDate: string;
  instructor: string;
  maxCapacity: number;
  enrolledCount: number;
  status: "scheduled" | "in-progress" | "completed" | "cancelled";
}

interface SessionsState {
  sessions: Record<string, Session[]>;
}

const generateMockSessions = (): Record<string, Session[]> => {
  const mockSessions: Record<string, Session[]> = {};

  // Generate sessions for first 10 courses
  for (let i = 1; i <= 10; i++) {
    const courseId = `course-${i}`;
    const numSessions = Math.floor(Math.random() * 3) + 1; // 1-3 sessions per course

    mockSessions[courseId] = [];
    for (let j = 0; j < numSessions; j++) {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() + Math.floor(Math.random() * 30));
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + Math.floor(Math.random() * 5) + 1);

      mockSessions[courseId].push({
        id: `session-${i}-${j}`,
        courseId,
        startDate: startDate.toISOString().split("T")[0],
        endDate: endDate.toISOString().split("T")[0],
        instructor: `Instructor ${Math.floor(Math.random() * 5) + 1}`,
        maxCapacity: Math.floor(Math.random() * 20) + 10,
        enrolledCount: Math.floor(Math.random() * 10),
        status: ["scheduled", "in-progress", "completed", "cancelled"][
          Math.floor(Math.random() * 4)
        ] as Session["status"],
      });
    }
  }

  return mockSessions;
};

const initialState: SessionsState = {
  sessions: generateMockSessions(),
};

const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    addSession: (
      state,
      action: PayloadAction<{
        courseId: string;
        session: Omit<Session, "id" | "enrolledCount">;
      }>,
    ) => {
      const { courseId, session } = action.payload;
      if (!state.sessions[courseId]) {
        state.sessions[courseId] = [];
      }
      state.sessions[courseId].push({
        ...session,
        id: `session-${Date.now()}`,
        enrolledCount: 0,
      });
    },

    updateSession: (
      state,
      action: PayloadAction<{
        courseId: string;
        sessionId: string;
        session: Partial<Session>;
      }>,
    ) => {
      const { courseId, sessionId, session } = action.payload;
      const sessionIndex = state.sessions[courseId]?.findIndex(
        (s) => s.id === sessionId,
      );
      if (sessionIndex !== -1) {
        state.sessions[courseId][sessionIndex] = {
          ...state.sessions[courseId][sessionIndex],
          ...session,
        };
      }
    },

    cancelSession: (
      state,
      action: PayloadAction<{ courseId: string; sessionId: string }>,
    ) => {
      const { courseId, sessionId } = action.payload;
      const sessionIndex = state.sessions[courseId]?.findIndex(
        (s) => s.id === sessionId,
      );
      if (sessionIndex !== -1) {
        state.sessions[courseId][sessionIndex].status = "cancelled";
      }
    },

    initializeCourseSessions: (state, action: PayloadAction<string>) => {
      const courseId = action.payload;
      if (!state.sessions[courseId]) {
        state.sessions[courseId] = [];
      }
    },
  },
});

// Selectors
export const selectSessionsByCourseId = (state: RootState, courseId: string) =>
  state.sessions.sessions[courseId] || [];

export const {
  addSession,
  updateSession,
  cancelSession,
  initializeCourseSessions,
} = sessionsSlice.actions;

export default sessionsSlice.reducer;
